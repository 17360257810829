<template>
  <v-container class="pa-0" fluid>
    <!-- loader -->
    <div v-if="loading" class="d-flex flex-column text-center py-12">
      <span class="subtext--text">Obtendo Destaques...</span>

      <loader-content />
    </div>

    <v-container v-else-if="highlights.length > 0">
      <!-- grid -->
      <MansoryGrid class="mb-12" :data="highlights" @refresh="getData()" />

      <v-row class="justify-center" no-gutters>
        <v-btn
          :disabled="pagination.page >= pagination.pageCount || loading"
          class="white--text"
          color="gradient"
          depressed
          @click="moreResults()"
        >
          Carregar mais
        </v-btn>
      </v-row>
    </v-container>

    <!-- empty -->
    <v-row v-else class="subtext--text justify-center py-4" no-gutters>
      <span>Nenhum resultado encontrado</span>
    </v-row>
  </v-container>
</template>


<script>
import { getHighlights } from "@/services/gallery";
import { formatDate } from "@/utils";
import MansoryGrid from "@/components/gallery/MansoryGrid";

export default {
  data() {
    return {
      loading: true,
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 30,
      },
      highlights: [],
    };
  },

  components: {
    MansoryGrid,
  },

  beforeMount() {
    this.getData();
  },

  methods: {
    // receive pictures
    async getData(concatData = false) {
      try {
        const payload = {
          page: String(this.pagination.page - 1),
          size: this.pagination.itemsPerPage,
        };

        await getHighlights(payload).then((res) => {
          const data = res.body.content;

          if (concatData) this.highlights = this.highlights.concat(data);
          else this.highlights = data;

          this.pagination.pageCount = res.body.totalPages;
        });
      } catch (err) {
        console.log(err.data);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },

    moreResults() {
      this.pagination.page++;
      this.getData(true);
    },

    formatDate,
  },
};
</script>